import React, { useMemo, useState } from 'react'
import { Checkbox, Stack, Typography } from '@mui/material'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { Statsig } from 'statsig-react'
import CheckImage from './checkImage'
import { AvailableUnit, RoomType, SelectedUnitInfoProps, SelectedUnitsType } from './contantsAndType'
import NoFloorPlan from '../../assets/imgs/noFloorPlan.png'

interface ScheduleTourFloorPlanProps {
  questionnaireId: string
  propertyId: string
  units: AvailableUnit[]
  selectedUnits: SelectedUnitsType
  isCoLivingProperty: boolean
  onchange: (selectedUnits: SelectedUnitsType) => void
}
const ScheduleTourFloorPlan = (props: ScheduleTourFloorPlanProps) => {
  const { units, selectedUnits, propertyId, onchange, questionnaireId, isCoLivingProperty } = props
  const onSelectValueChange = (value: { unitsInfo: SelectedUnitInfoProps } | undefined, key: string) => {
    const newSelectedUnits = new Map(selectedUnits)
    if (value) {
      newSelectedUnits.set(key, value)
    } else {
      newSelectedUnits.delete(key)
    }
    onchange(newSelectedUnits)
  }

  return (
    <div className='px-[40px] py-[16px] lg:py-[32px]  lg:pt-0 lg:px-[80px] grid grid-cols-1 gap-y-[40px]'>
      {units.map(unit => (
        <ScheduleTourFloorPlanItem
          isCoLivingProperty={isCoLivingProperty}
          propertyId={propertyId}
          questionnaireId={questionnaireId}
          key={unit.id}
          unit={unit}
          value={selectedUnits.get(`${unit.name}`)}
          onChange={(value: { unitsInfo: SelectedUnitInfoProps } | undefined) => {
            onSelectValueChange(value, unit.name)
          }}
        />
      ))}
    </div>
  )
}

interface ScheduleTourFloorPlanItemProps {
  questionnaireId: string
  unit: AvailableUnit
  propertyId: string
  value: { unitsInfo: SelectedUnitInfoProps } | undefined
  isCoLivingProperty: boolean
  onChange: (value: { unitsInfo: SelectedUnitInfoProps } | undefined) => void
}
const ScheduleTourFloorPlanItem = (props: ScheduleTourFloorPlanItemProps) => {
  const { unit, value, onChange, propertyId, questionnaireId, isCoLivingProperty } = props
  const [checkImage, setCheckImage] = useState(false)
  const floorPlanPrice = useMemo(() => {
    const { maxRentPrice, minRentPrice } = unit

    return {
      minPrice: Number((minRentPrice.discountAmount || 0) / 100),
      maxPrice: Number((maxRentPrice.discountAmount || 0) / 100),
    }
  }, [unit])
  const floorPlanTitle = useMemo(() => {
    const { floorPlanBedroomNum, name } = unit

    const planName = name ? ` - ${name}` : ''
    return Number(floorPlanBedroomNum) === 0 ? `Studio${planName}` : name
  }, [unit])

  const floorPlanArea = useMemo(() => {
    const minArea = Number(unit.minSqrFt)
    const maxArea = Number(unit.maxSqrFt)
    return minArea === maxArea ? `${minArea} sqft` : `${minArea} - ${maxArea} sqft`
  }, [unit])

  const selectUnit = useMemo(() => value && value.unitsInfo.unitName === unit.name, [value?.unitsInfo.unitName])
  const onCheckImage = () => {
    Statsig.logEvent('schedule_tour_click_unit_image', window.location.href, {
      questionnaireId,
      unitName: unit.name,
      floorUrl: unit.floorPlanPicUrl,
      propertyId,
    })
    setCheckImage(true)
  }

  const changeUnitSelect = () => {
    if (value) {
      onChange(undefined)
    } else {
      onChange({
        unitsInfo: { floorPlanIndex: unit.floorPlanIndex, unitName: unit.name, rooms: [], unitTitle: unit.name, unitId: Number(unit.id) },
      })
    }
  }
  const changeUnitItemSelect = (room: RoomType) => {
    const { id, name, availableStatus, preTourEndDate, preTourStartDate, availableStartTime } = room
    if (value) {
      const newValue = cloneDeep(value)
      const { rooms } = newValue.unitsInfo
      const target = rooms.findIndex((u: { id: any }) => String(u.id) === String(id))
      if (target > -1) {
        rooms.splice(target, 1)
      } else {
        rooms.push({ name, id: Number(id), availableStatus, availableStartTime, preTourStartDate, preTourEndDate, unitId: Number(unit.id) })
      }
      onChange(newValue)
    } else {
      onChange({
        unitsInfo: {
          unitTitle: unit.name,
          floorPlanIndex: unit.floorPlanIndex,
          unitName: unit.name,
          unitId: Number(unit.id),
          rooms: [{ name, id: Number(id), availableStatus, availableStartTime, preTourStartDate, preTourEndDate, unitId: Number(unit.id) }],
        },
      })
    }
  }

  return (
    <div className={`p-[32px] border rounded-[16px] ${selectUnit ? 'border-[#050A22]' : 'border-[#E0E2EF]'}`}>
      <CheckImage
        src={unit.floorPlanPicUrl}
        alt=''
        onClose={() => {
          setCheckImage(false)
        }}
        visible={checkImage}
      />
      <div className='flex'>
        {unit.floorPlanPicUrl ? (
          <img alt='' src={unit.floorPlanPicUrl} aria-hidden='true' onClick={onCheckImage} className='w-[176px] h-[176px]' />
        ) : (
          <div className='w-[176px] h-[176px] flex flex-col justify-center items-center'>
            <img src={NoFloorPlan} alt='' className='w-[96px] h-[96px]' />
            <Typography className='!font-Averta6 font-bold !text-[24px] !text-[#050A22] !capitalize'>Coming Soon</Typography>
          </div>
        )}
        <div
          aria-hidden='true'
          onClick={changeUnitSelect}
          className={`${unit.floorPlanPicUrl ? 'ml-[32px]' : ''} flex-grow flex justify-between flex-col`}
        >
          <div className='w-full relative mb-[10px]'>
            <div className='mr-[52px]'>
              <Typography className='!font-Averta6 !font-semibold !text-[32px] !text-[#050A22] break-all'>{floorPlanTitle}</Typography>
              <Stack direction='row' spacing={1}>
                <Typography className='table px-[16px] py-[4px] rounded-[36px] !text-[20px] !text-[#050A22] !font-Averta4 bg-slate-50'>
                  {`${unit.floorPlanBedroomNum} beds, ${unit.floorPlanBathroomNum} baths`}
                </Typography>
                <Typography className='table px-[16px] py-[4px] rounded-[36px] !text-[20px] !text-[#050A22] !font-Averta4 bg-slate-50'>
                  {floorPlanArea}
                </Typography>
              </Stack>
            </div>
            <Checkbox
              className='!absolute !top-0 !right-0'
              sx={{
                color: '#E0E2EF',
                padding: '0',
                '&.Mui-checked': {
                  color: '#050A22',
                },
                '& svg': {
                  fontSize: '18px',
                },
              }}
              checked={!!value && value.unitsInfo.floorPlanIndex === unit.floorPlanIndex}
            />
          </div>
          <div className='flex justify-between items-center'>
            <Typography className='!font-Averta6 !text-[#050A22] !text-[32px]'>{`$${floorPlanPrice.minPrice.toLocaleString()} - $${floorPlanPrice.maxPrice.toLocaleString()}`}</Typography>
          </div>
        </div>
      </div>
      {selectUnit && unit.rooms && (
        <div className='mt-[40px]'>
          <Typography className='!font-averta !text-[28px] !text-[#050A22]'>
            Select your preferred {isCoLivingProperty ? 'rooms' : 'units'} (optional)
          </Typography>
          <div className='grid grid-cols-1 gap-y-[24px] mt-[24px]'>
            {Array.isArray(unit.rooms) &&
              unit.rooms.length > 0 &&
              unit.rooms.map((room: RoomType) => {
                const { id, name, rentPrice, tourAvailableStatus, tourAvailableStartTime, rentPriceLeaseTerm, privateBathroom } = room
                return (
                  <div
                    key={id}
                    aria-hidden='true'
                    onClick={() => changeUnitItemSelect(room)}
                    className='rounded-[16px] bg-[#F7F9FC] p-[16px]'
                  >
                    <div className='flex justify-between items-center leading-[60px]'>
                      <Typography className='!font-Averta6 !text-[#050A22] !text-[32px] !font-semibold'>{name}</Typography>
                      <div className='flex items-center'>
                        <Typography className='!font-Averta6 !text-[#050A22] !text-[32px] !font-semibold'>
                          {rentPrice ? `$${Number(rentPrice.discountAmount / 100).toLocaleString()}` : ''}
                        </Typography>
                        {rentPrice && rentPrice.leaseTerm && (
                          <Typography className='!ml-[8px] !text-[20px] lg:!text-[24px] !font-Averta4 !text-[#75798D]'>
                            {rentPriceLeaseTerm}
                          </Typography>
                        )}
                        &nbsp;&nbsp;
                        <Checkbox
                          sx={{
                            color: '#E0E2EF',
                            padding: '0',
                            background: '#fff',
                            '&.Mui-checked': {
                              color: '#050A22',
                            },
                            '& svg': {
                              fontSize: '18px',
                            },
                          }}
                          checked={!!value && value.unitsInfo.rooms.some((u: { id: any }) => String(u.id) === String(id))}
                        />
                      </div>
                    </div>
                    <Stack direction='row' justifyContent='space-between'>
                      <div className='font-Averta4 text-[#050A22] text-[24px]'>
                        {privateBathroom ? 'Private bathroom' : 'Shared bathroom'}
                      </div>
                      {tourAvailableStatus === 0 && <div className='font-Averta4 text-[#050A22] text-[24px]'>Available now</div>}
                      {tourAvailableStatus === 1 && (
                        <div className='font-Averta4 text-[#050A22] text-[24px]'>
                          Available from {moment(tourAvailableStartTime).format('MM/DD/YYYY')}
                        </div>
                      )}
                      {tourAvailableStatus === 2 && (
                        <div className='font-Averta4 text-[#050A22] text-[24px]'>
                          Available from {moment(room.preTourStartDate).format('MM/DD/YYYY')} to
                          {moment(room.preTourEndDate).format('MM/DD/YYYY')}
                        </div>
                      )}
                    </Stack>
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default ScheduleTourFloorPlan
