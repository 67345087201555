import React from 'react'
import { useSearchParams } from 'react-router-dom'
import VersionStable from './versionStable/index'
import VersionV5 from './versionv5/index'

const FormVersion = () => {
  const [searchParams] = useSearchParams()
  const version = searchParams.get('version')
  const versionComponent = {
    4: <VersionStable />,
    5: <VersionV5 />,
  }[Number(version)]

  return <div>{versionComponent}</div>
}

export default FormVersion
