import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ScheduleTourPropertyType } from './contantsAndType'
import { getCurrency } from '../../utils/index'
import CalendarFilled from '../../icons/calendarFilled'

const PropertyCard = (props: { property: ScheduleTourPropertyType; onClick: () => void }) => {
  const { property, onClick } = props

  return (
    <Box style={{ boxShadow: '0px 1px 7px 0px #242A2E1A' }} className='rounded-[24px] overflow-hidden' onClick={onClick}>
      <img src={property.headPicUrl} alt='tripalink' className='w-auto' />
      <div className='p-[24px] flex items-start flex-col '>
        {property.minPrice && (
          <div className='mb-[16px] flex flex-row items-baseline justify-start'>
            <Typography className='!font-Averta6 !font-bold !text-[#050A22] !text-[44px] '>{`$${getCurrency(
              property.minPrice,
            )}+/mo`}</Typography>
            &nbsp;&nbsp;
            {property.minOriginalPrice && Number(property.minOriginalPrice) > Number(property.minPrice) && (
              <Typography className='!font-Averta4 line-through  !text-[#75798D] !text-[28px] '>
                {`$${getCurrency(property.minOriginalPrice)}`}
              </Typography>
            )}
          </div>
        )}
        <Typography className='!font-Averta6 !text-[#050A22] !text-[32px] !mb-[16px]'>{property.aliasName || property.name}</Typography>

        <Box className='w-full overflow-x-scroll'>
          {property.amenities.length > 0 && (
            <Stack direction='row' spacing={1}>
              {property.amenities.map(amenity => (
                <span
                  key={amenity.catalogName}
                  className='bg-[#F7F9FC] rounded-[16px] px-[16px] py-[8px] text-[#75798D] font-Averta4 text-[28px] whitespace-nowrap'
                >
                  {amenity.catalogName}
                </span>
              ))}
            </Stack>
          )}
        </Box>
      </div>
    </Box>
  )
}

export default PropertyCard
