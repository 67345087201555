import React, { useState, forwardRef, useMemo, useRef, useLayoutEffect, useEffect } from 'react'
import moment from 'moment-timezone'
import cn from 'classnames'
import { debounce, findIndex, slice, toArray } from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Box, CircularProgress } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider'
import { QUERY_TOUR_AGENT_TIMELINE } from '../../graphqls/queries'
import { ScheduleTourPropertyType, SelectedUnitsType } from './contantsAndType'
import Earth from '../../icons/tripalinkTourV2/earth'
import { getSubmitPropertyInfo } from './utils'

interface DateType {
  day: string
  isOptional: boolean
  monthStr: string
  value: string
  weekDayStr: string
  year: string
}

interface DayTimeType {
  valueStartSecond: number
  isOptional: boolean
  value: string
  adminUserId: number
  adminUserName: string
}

const createCheckIsNoAvailableDay = (today: string) => (dayList: any[]) =>
  dayList.slice(0, 14).every((day: any) => moment(day.value).diff(today, 'day') >= 0 && !day.isOptional)

function ReserveCalendar ({
  selectedProperty,
  selectedUnits,
  onChange,
  onBackUserId,
  onNoAvailableDay,
  selectedStyle = 'bg-default text-white',
  adminUserId,
  timeZoneText,
  timeZone,
  isCentralizedStage,
  prospectId,
}: {
  onChange?: (value: { date?: string; time: string; startSecond: string }) => void
  onBackUserId?: (value: { newAdminUserId: string; newAdminUserName: string }) => void
  onNoAvailableDay?: () => void
  selectedStyle?: string
  adminUserId: string
  timeZoneText?: string
  timeZone: string
  prospectId?: string
  isCentralizedStage?: boolean
  selectedUnits: SelectedUnitsType
  selectedProperty?: ScheduleTourPropertyType | undefined
}) {
  const [selectTime, setSelectTime] = useState({ time: '', second: '' })
  const [currentDate, setCurrentDate] = useState(moment().format('ll'))
  const [calendarEleMap] = useState(new Map())
  const calendarContainerRef = useRef<HTMLDivElement | null>(null)
  const currentVisibleEleMapRef = useRef<Map<string, IntersectionObserverEntry> | null>(calendarEleMap)
  const [calenderData, setCalenderData] = useState<any>()

  const today = useMemo(() => moment()?.tz(timeZone)?.format('YYYY-MM-DD'), [timeZone])

  const checkIsNoAvailableDay = useMemo(() => createCheckIsNoAvailableDay(today), [today])
  const propertyInfo = useMemo(() => getSubmitPropertyInfo(selectedUnits, selectedProperty), [selectedUnits, selectedProperty])
  const variablesInput = useMemo(
    () => ({
      timezone: timeZone,
      source: 'USER',
      propertyInfo,
      prospectId: Number(prospectId),
      propertyId: Number(selectedProperty?.id),
      ...(isCentralizedStage ? {} : { adminUserId: adminUserId || null }),
    }),
    [selectedProperty, timeZone, prospectId, isCentralizedStage, propertyInfo, adminUserId],
  )

  const [fetchTimeline, { loading }] = useLazyQuery(QUERY_TOUR_AGENT_TIMELINE, {
    onCompleted: setCalenderData,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })
  const { data: tourCalenderData } = calenderData?.queryTourAgentTimeline || {}
  const { tourDuration, dayList = [], selectDayTimeline = [], day } = tourCalenderData || {}
  const selectDayTimelineData = selectDayTimeline?.filter((item: { isOptional: boolean }) => item.isOptional) || []

  useEffect(() => {
    fetchTimeline({ variables: { input: variablesInput } })
  }, [])

  useEffect(() => {
    if (!isCentralizedStage && onBackUserId) {
      const newAdminUserId = tourCalenderData?.adminUserId
      const newAdminUserName = tourCalenderData?.adminUserName
      if (newAdminUserId || newAdminUserName) {
        onBackUserId({ newAdminUserId, newAdminUserName })
      }
    }
  }, [isCentralizedStage, tourCalenderData?.adminUserId, tourCalenderData?.adminUserName])

  useEffect(() => {
    const areAllUnavailable = dayList?.every((item: { isOptional: boolean }) => !item.isOptional)
    if (tourCalenderData === null || (dayList?.length && checkIsNoAvailableDay(dayList)) || (dayList?.length && areAllUnavailable)) {
      onNoAvailableDay?.()
    }
  }, [dayList, tourCalenderData])

  const newDayList = useMemo<DateType[]>(() => {
    const startIndex = findIndex(dayList, { value: today })
    return slice(dayList, startIndex, startIndex + 15)
  }, [dayList, today])

  const getBtnStyle = (value: string) => {
    if (day) {
      if (day === value) {
        return selectedStyle
      }
      return today === value ? 'bg-slate-200 text-default' : ''
    }
    return ''
  }

  const getFirstDay = debounce(() => {
    const currentVisibleDate = Array.from(currentVisibleEleMapRef.current?.keys() || []).sort((a, b) => moment(a).unix() - moment(b).unix())
    setCurrentDate(currentVisibleDate[0] || '')
  }, 500)

  const handleIntersect = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(item => {
      const dataValue = item.target.getAttribute('data-value') || ''

      if (currentVisibleEleMapRef.current?.has(dataValue) && !item.isIntersecting) {
        currentVisibleEleMapRef.current?.delete(dataValue)
      } else if (item.isIntersecting) {
        currentVisibleEleMapRef.current?.set(dataValue, item)
      }
    })
    getFirstDay()
  }

  useLayoutEffect(() => {
    const options = {
      root: calendarContainerRef.current,
      rootMargin: '0px',
      threshold: 0.7,
    }
    const observer = new IntersectionObserver(handleIntersect, options)

    if (dayList.length) {
      const childrenList = Array.prototype.slice.call(calendarContainerRef?.current?.children || [])
      childrenList.forEach(ele => {
        observer.observe(ele)
      })
    }
    return () => {
      observer.disconnect()
    }
  }, [dayList])

  return (
    <Box className='flex flex-col gap-[24px]'>
      {loading && !currentDate ? (
        <Skeleton height={40} />
      ) : (
        <Box className='flex justify-between h-[48px]'>
          <Box className='flex items-center gap-[24px] text-[32px]'>
            <span className='font-averta font-semibold'>{moment(currentDate).format('MMM')}</span>
            <Divider orientation='vertical' sx={{ height: 8 }} />
            <span className='font-averta font-semibold'>{moment(currentDate).year()}</span>
          </Box>
          <Box className='flex'>
            <span className='font-averta text-[28px]'>{`Duration: ${tourDuration || '-'}`}</span>
          </Box>
        </Box>
      )}

      <Box className='flex flex-col gap-[32px]'>
        {loading ? (
          <Skeleton height={90} />
        ) : (
          <Box ref={calendarContainerRef} className='overflow-x-scroll flex pb-[32px] gap-[36px] w-full'>
            {newDayList?.map((date: DateType) => (
              <Box key={`reserve-calendar-date-${date.value}`} data-value={date.value} className='flex flex-col gap-[20px]'>
                <Box className='text-[28px] text-zinc-400 text-center select-none font-averta'>{date.weekDayStr}</Box>
                <button
                  type='button'
                  disabled={!date.isOptional}
                  data-date={date.value}
                  onClick={({
                    currentTarget: {
                      dataset: { date = '' },
                    },
                  }) => {
                    fetchTimeline({ variables: { input: { ...variablesInput, day: date } } })
                    setSelectTime({
                      time: '',
                      second: '',
                    })
                    onChange?.({
                      date,
                      time: '',
                      startSecond: '',
                    })
                  }}
                  className={`text-[24px] font-averta font-bold w-[64px] h-[64px] flex justify-center items-center
                    ${date.isOptional ? 'cursor-pointer' : 'text-zinc-400 cursor-not-allowed'}
                    ${getBtnStyle(date.value)}
                    ${!date.isOptional ? 'line-through' : ''}
                    rounded-full cursor-pointer select-none`}
                >
                  {date.day}
                </button>
              </Box>
            ))}
          </Box>
        )}
        {loading ? (
          <Box>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Box>
        ) : (
          <Box className='flex gap-[32px] flex-col'>
            <Box className='flex flex-wrap gap-[24px] lg:grid lg:grid-cols-4 lg:gap-2.5 '>
              {loading ? (
                <Box className='w-full flex justify-center items-center h-[300px]'>
                  <CircularProgress />
                </Box>
              ) : (
                selectDayTimelineData?.map((time: DayTimeType) => (
                  <button
                    onClick={({
                      currentTarget: {
                        dataset: { time = '', second = '', adminUserId = '', adminUserName = '' },
                      },
                    }) => {
                      setSelectTime({ time, second })
                      onChange?.({ time: `${day} ${time}`, startSecond: second })
                      onBackUserId?.({
                        newAdminUserId: isCentralizedStage ? Number(adminUserId) : tourCalenderData.adminUserId,
                        newAdminUserName: isCentralizedStage ? adminUserName : tourCalenderData.adminUserName,
                      })
                    }}
                    data-admin-user-id={time?.adminUserId}
                    data-admin-user-name={time?.adminUserName}
                    disabled={!time.isOptional}
                    key={`reserve-calendar-time-${time.value}`}
                    data-time={time.value}
                    data-second={time.valueStartSecond}
                    type='button'
                    className={cn(
                      'rounded-[100px] flex items-center lg:w-full border-[2px] w-[144px] h-[64px] justify-center border-slate-100 font-averta text-[28px] select-none text-center',
                      time.isOptional ? 'cursor-pointer' : 'text-gray-350 cursor-not-allowed',
                      time.value === selectTime.time ? 'bg-gray-900 text-white' : '',
                    )}
                  >
                    {time?.value?.toLocaleUpperCase()?.replaceAll?.('0A', '0 A')?.replaceAll?.('0P', '0 P')}
                  </button>
                ))
              )}
            </Box>
            <Box className='flex gap-[4px] items-center'>
              <Earth />
              <p className='text-[28px] font-semibold font-averta'>{timeZoneText || '--'}</p>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default forwardRef(ReserveCalendar)
