import React, { useMemo } from 'react'
import SelectedForm from './selectedForm'
import SchedultTourWayAndTime from './scheduleTourWayAndTime'
import ScheduleTourPreference from './scheduleTourPreference'
import { getTourTypeName } from './contantsAndType'
import ErrorSnackbar from '../errorSnackbar'

export default function SubmitTourPc ({
  selfGuidTourUnits,
  prospectId,
  property,
  preference: selectedPreferences,
  onChangeTime: setSelectedAgentTime,
  onBackNewUserId: setNewAdminUserId,
  onChangeType: setTourType,
  agentInfo,
  selectedUnits,
  selectedProperty,
  timeZoneInfo: selectedPropertyTimeZone,
  type: tourType,
  typeList: tourTypeList,
  setSelectedPreferences,
  havePerference,
  isReschedule,
  setAddTourError,
  addTourError,
  isCentralizedStage,
  onNoAvailableDay,
}: any) {
  return (
    <div className='min-h-[calc(100vh-150px)] pt-[40px] pb-[190px] px-[40px]'>
      <SelectedForm
        selectedUnits={selectedUnits}
        property={property}
        preference={selectedPreferences}
        tourOption={isReschedule ? getTourTypeName(tourType) : ''}
      />

      {havePerference && !isReschedule && (
        <ScheduleTourPreference selectedPreferences={selectedPreferences} onChange={setSelectedPreferences} />
      )}

      <SchedultTourWayAndTime
        isCentralizedStage={isCentralizedStage}
        selfGuidTourUnits={selfGuidTourUnits}
        prospectId={prospectId}
        typeList={tourTypeList}
        type={tourType}
        timeZoneInfo={selectedPropertyTimeZone}
        selectedUnits={selectedUnits}
        selectedProperty={selectedProperty}
        onChangeType={setTourType}
        agentInfo={agentInfo}
        onChangeTime={setSelectedAgentTime}
        isReschedule={isReschedule}
        onBackNewUserId={setNewAdminUserId}
        onNoAvailableDay={onNoAvailableDay}
      />
      <ErrorSnackbar setAddTourError={setAddTourError} addTourError={addTourError} />
    </div>
  )
}
