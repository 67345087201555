import React, { FC, useMemo, useState, useRef, useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { Box, Divider, Typography, Dialog } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  ScheduleTourPropertyType,
  ScheduleTourTourType,
  TourCancelReasonList,
  TourStatusList,
  SelectedUnitsType,
  PageTypeType,
  getTourTypeName
} from './contantsAndType'
import TourCompleteImg from '../../assets/imgs/tourComplete.png'
import DateIcon from '../../icons/scheduleTour/date'
import TourTypeIcon from '../../icons/scheduleTour/tourType'
import HouseIcon from '../../icons/scheduleTour/houseIcon'
import AddressIcon from '../../icons/scheduleTour/address'
import { getCurrency } from '../../utils/index'
import { TOUR_CANCEL } from '../../graphqls/mutations'
import { QUERY_TOUR_LIST_BY_PROSPECT_ID } from '../../graphqls/queries'
import { checkIsMobile } from '../../utils/agent'
import SelectedForm from './selectedForm'
import { fillOnlySelectFloorplanSelectedUnits } from './utils'
import CustomAddToCalendarButton from './customAddToCalendarButton'

const isMobile = checkIsMobile()
const IMG_URL = process.env.REACT_APP_IMG_URL
const TourDetailImg = `${IMG_URL}0674ca3028875846.png`
const TourCancelImg = `${IMG_URL}59c95d51933c34c0.png`
const DialogCloseIcon = `${IMG_URL}0f8060e81ecba45d.png`

const TourHeaderImg = {
  SCHEDULED: TourDetailImg,
  CANCELED: TourCancelImg,
  FINISHED: TourCompleteImg
}

interface ScheduleTourDetailProps {
  tourList: ScheduleTourTourType[]
  propertyId: string
  propertyList: ScheduleTourPropertyType[]
  property: { name: string; address: string }
  selectedUnits: SelectedUnitsType
  selectedProperty: ScheduleTourPropertyType | undefined
  // eslint-disable-next-line react/require-default-props
  preference?: string[]
  onClickCard: (propertyId: string) => void
  setPageType: (value: PageTypeType) => void
  setRescheduleStatus: (value: boolean) => void
  setCurrTourId: (value: number) => void
  prospectId: string
  setScheduledUnitIds: (value: string[]) => void
  onAnotherCallback?: () => void
  prospectUserName: string
}

const ScheduleTourDetail: FC<ScheduleTourDetailProps> = props => {
  const {
    tourList,
    propertyId,
    propertyList,
    onClickCard,
    property,
    selectedProperty,
    selectedUnits,
    preference,
    setPageType,
    setRescheduleStatus,
    prospectId,
    setScheduledUnitIds,
    setCurrTourId,
    onAnotherCallback,
    prospectUserName
  } = props

  const [activeCancelReason, setCancelReason] = useState('')
  const [cancelReasonNote, setCancelReasonNote] = useState('')
  const [isShowCancelReason, setShowCancelReason] = useState(false)
  const [isShowTourInfo, setShowTourInfo] = useState(false)
  const [currentTour, setCurrTour] = useState<ScheduleTourTourType>()
  const showAddToCalendarButtonPropertyIds = process.env.REACT_APP_SHOW_ADD_CALENDEA_BUTTON_PROPERTY_IDS?.split(",") || []
  const isOutdatedTour = useMemo(() => moment(currentTour?.startTime).isBefore(moment()), [currentTour?.startTime])

  const [cancelTour, { loading: cancelTourLoading }] = useMutation(TOUR_CANCEL, {
    notifyOnNetworkStatusChange: true,
    onCompleted: res => {
      const { cancelTour = {} } = res || {}
      const { ret } = cancelTour || {}

      if (ret) {
        setShowCancelReason(false)
        queryTourListByProspectIdApi()
      }
    }
  })

  const [queryTourListByProspectIdApi] = useLazyQuery(QUERY_TOUR_LIST_BY_PROSPECT_ID, {
    fetchPolicy: 'network-only',
    variables: {
      prospectId
    },
    onCompleted(res) {
      const { queryTourListByProspectId } = res || {}
      const { data } = queryTourListByProspectId || {}
      const curTourList = Array.isArray(data) ? data : []
      const { id } = currentTour || {}
      setCurrTour(curTourList.find(item => propertyId && String(item.propertyId) === String(propertyId) && id === item.id))
    }
  })

  const { tourAgentId, eventId, units } = currentTour || {}

  useEffect(() => {
    const tourSchedule = tourList.find(item => propertyId && String(item.propertyId) === String(propertyId))
    setCurrTour(tourSchedule)
  }, [])

  const onCancelTour = () => {
    cancelTour({
      variables: {
        input: {
          tourId: Number(currentTour?.id),
          eventId,
          cancelReason: activeCancelReason,
          cancelNote: activeCancelReason === TourCancelReasonList[3] ? cancelReasonNote : '',
          userId: tourAgentId,
          cancelSource: 'USER'
        }
      }
    })
  }

  const onReScheduleTour = () => {
    setPageType('unit')
    setRescheduleStatus(false)
    setCurrTourId(0)
  }

  const onReScheduleTourSubmit = () => {
    setPageType('submitTour')
    setRescheduleStatus(true)
    setCurrTourId(Number(currentTour?.id || 0))
    const currTourUnitIds = units?.map(item => String(item.unitId))
    setScheduledUnitIds(currTourUnitIds && currTourUnitIds.length > 0 ? currTourUnitIds : [''])
  }
  const onBack = () => {
    setPageType('unit')
    onAnotherCallback?.()
  }

  const tourUnitStr = useMemo(() => {
    if (!currentTour) return ''
    return currentTour.units
      .map(item => {
        if (item.unitName && item.unitId) {
          return `${item.floorPlanName} - ${item.unitName}`
        }
        return `${item.floorPlanName} - Other Unit`
      })
      .join('; ')
  }, [currentTour])
  const noTourPropertyList = useMemo(
    () => propertyList.filter(property => tourList.every(tour => String(tour.propertyId) !== String(property.id))),
    [tourList, propertyList]
  )
  const haveTourPropertyList = useMemo(() => {
    const allHaveTourPropertyList = propertyList.filter(property => tourList.some(tour => String(tour.propertyId) === String(property.id)))
    const result = allHaveTourPropertyList.filter(property => !!currentTour && String(property.id) !== String(currentTour.propertyId))
    return result
  }, [currentTour, tourList, propertyList])
  if (!currentTour) return null
  return (
    <div className="pm-[40px] schedule-tour-detail">
      <div className="px-[40px] py-[32px] flex flex-col items-center">
        <img className="w-[160px] h-[160px]" src={TourHeaderImg[currentTour.status]} alt="tour detial" />
        <Typography className="!mt-[32px] !font-Averta6 !text-[36px] !text-center">
          Your {getTourTypeName(currentTour.tourType)?.toLocaleLowerCase()} is {TourStatusList[currentTour.status]}
        </Typography>
      </div>
      {currentTour.status !== 'CANCELED' ? (
        <>
          <div className="w-[calc(100%-80px)] m-auto my-[32px] rounded-[32px] bg-[#F2F6FD]">
            <div className="px-[40px] py-[24px] flex items-start">
              <Typography className="!text-[#75798D] !font-Averta4 !text-[32px]">Tour Host</Typography>
              <Divider orientation="vertical" variant="middle" sx={{ borderColor: '#E0E2EF', margin: '0 8px' }} flexItem />
              <Typography className="!text-[#050A22] !font-Averta6 !text-[32px]">{currentTour.tourAgentName}</Typography>
            </div>
            <div className="bg-white rounded-[32px] px-[40px] py-[40px]" style={{ boxShadow: '0px 4px 10px 0px #0000000A' }}>
              <div className="grid grid-cols-1 gap-y-[32px]">
                <div className="flex items-start justify-start leading-[48px]">
                  <DateIcon className="!text-[32px] mt-[5px]" />
                  &nbsp;
                  <Typography className="!font-Averta6 !text-[32px] !text-[#050A22]">
                    {currentTour.startTime
                      ? `${momentTimezone(currentTour.startTime).tz(currentTour.timeZone).format('h:mm a, dddd, MMM DD, YYYY')} ${
                          currentTour.timeZoneText
                        }`
                      : '-'}
                  </Typography>
                </div>
                <div className="flex items-start justify-start leading-[48px]">
                  <HouseIcon className="!text-[32px] mt-[5px]" />
                  &nbsp;
                  {Array.isArray(currentTour.units) && currentTour.units.length ? (
                    <div className="flex flex-col items-start justify-center">
                      <Typography className="text-themeColor !text-[32px] !font-Averta6">{currentTour?.propertyName}</Typography>
                      <Typography className="text-themeColor !text-[28px] !font-Averta4">{tourUnitStr}</Typography>
                    </div>
                  ) : (
                    <Typography className="text-themeColor !text-[32px] !font-Averta6">{currentTour?.propertyName}</Typography>
                  )}
                </div>

                <div className="flex items-start justify-start leading-[48px]">
                  <AddressIcon className="!text-[32px] mt-[5px]" />
                  &nbsp;
                  <Typography className="!font-Averta6 !text-[32px] text-themeColor">{currentTour.propertyAddress || '-'}</Typography>
                </div>
                <div className="flex items-start justify-start leading-[48px]">
                  <TourTypeIcon className="!text-[32px] mt-[5px]" />
                  &nbsp;
                  <Typography className="!font-Averta6 !text-[32px] text-themeColor">
                    {currentTour?.tourType ? getTourTypeName(currentTour.tourType) : '-'}
                  </Typography>
                </div>
                {currentTour.preference && <Divider orientation="horizontal" />}

                {Array.isArray(currentTour.preference) && currentTour.preference.length > 0 && (
                  <div className="flex flex-col items-start justify-center">
                    <Typography className="!text-[#75798D] !text-[28px] !font-Averta4">Preference</Typography>
                    <Typography className="!text-[#050A22] !text-[32px] !font-Averta6">{currentTour.preference.join('; ')}</Typography>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!isOutdatedTour && currentTour.status === 'SCHEDULED' && (
            <>
              {showAddToCalendarButtonPropertyIds.includes(currentTour.propertyId.toString()) && (
                <div className="w-full px-[32px] mb-1">
                  <CustomAddToCalendarButton data={{ ...currentTour, prospectUserName }} />
                </div>
              )}
              <div className="flex px-[40px] text-center font-semibold font-Averta6">
                <div
                  className="rounded-[24px] w-1/2 btn font-semibold !text-[32px] py-[24px] cursor-pointer"
                  onClick={() => setShowCancelReason(true)}
                >
                  Cancel
                </div>
                <div
                  className="rounded-[24px] w-1/2 ml-[16px] btn font-semibold !text-[32px] py-[24px] cursor-pointer"
                  onClick={onReScheduleTourSubmit}
                >
                  Reschedule
                </div>
              </div>
            </>
          )}
          {(isOutdatedTour || currentTour.status === 'FINISHED') && (
            <div className="w-full px-2 mt-1">
              <button
                onClick={onBack}
                type="button"
                aria-label="reschedule"
                className="bg-[#E74F4F] text-[32px] font-bold  w-full h-5 rounded text-white"
              >
                Schedule another tour
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          {currentTour.startTime && (
            <div
              className={`font-semibold cancel-tour-info ${
                isMobile ? 'w-[calc(100%-80px)]' : 'w-[670px]'
              } m-auto h-[112px] px-[32px] leading-[112px] text-[32px] rounded-[16px] bg-[#F7F9FC] flex justify-between mb-[112px]`}
            >
              <span>{`${momentTimezone(currentTour.startTime).tz(currentTour.timeZone).format('h:mm a, dddd, MMM DD, YYYY')}`}</span>
              <span className="underline decoration-1 cursor-pointer" onClick={() => setShowTourInfo(true)}>
                View
              </span>
            </div>
          )}
          <div
            className={`${
              isMobile ? 'w-[calc(100%-80px)]' : 'w-[670px]'
            } m-auto font-semibold text-center rounded-[24px] !text-[32px] h-[96px] leading-[96px] cursor-pointer bg-red-450 text-[#fff]`}
            onClick={onReScheduleTour}
          >
            Schedule tour
          </div>
        </>
      )}

      {noTourPropertyList.length > 0 && (
        <div className="w-[calc(100%-80px)] m-auto mt-[64px] mb-[32px]">
          <Typography className="!font-Averta6 !text-[#181414] !text-[36px] !mb-[32px]">Other properties you may want to tour</Typography>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-2.5 gap-y-[40px]">
            {noTourPropertyList.map(item => (
              <PropertyCard property={item} key={item.id} onClick={() => onClickCard(item.id)} />
            ))}
          </div>
        </div>
      )}
      {haveTourPropertyList.length > 0 && (
        <div className="w-[calc(100%-80px)] m-auto mt-[64px] mb-[32px]">
          <Typography className="!font-Averta6 !text-[#181414] !text-[36px] !mb-[32px]">Property has scheduled for tour</Typography>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-2.5 gap-y-[40px]">
            {haveTourPropertyList.map(item => (
              <PropertyCard property={item} key={item.id} onClick={() => onClickCard(item.id)} />
            ))}
          </div>
        </div>
      )}
      {/* Reason list PC or mobile */}
      <Dialog
        open={isShowCancelReason}
        className={`${isMobile ? 'mobile-box' : ''} reason-list-dialog-box`}
        onClose={() => setShowCancelReason(false)}
      >
        {isMobile && (
          <div className="text-center mt-[16px] mb-[16px] w-[44px] mx-auto">
            <div className="w-[44px] h-[4px] rounded-full bg-[#C7CBD1]" />
          </div>
        )}

        <p className="text-[#050A22] text-[32px] mt-[40px] font-semibold mb-[48px]">Reason for cancellation</p>
        <div>
          {TourCancelReasonList.map(item => (
            <div
              key={item}
              onClick={() => setCancelReason(item)}
              className={`${
                activeCancelReason === item ? '!border-[2px] !border-[#050A22] !bg-[#F7F9FC]' : ''
              } text-[28px] h-[96px] leading-[96px] font-normal rounded-[200px] border border-slate-200 pl-[48px] mb-[24px] cursor-pointer`}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="other-reason-note h-[200px]">
          {activeCancelReason === TourCancelReasonList[3] && (
            <textarea
              className="reason-text-area w-full border-[2px] border-gray-550 rounded-[24px] resize-none text-[24px] px-[40px] py-[18px]"
              placeholder="Please specify the reason (optional)"
              onChange={e => setCancelReasonNote(e.currentTarget.value)}
            />
          )}
        </div>
        <div className="flex text-center font-semibold mb-[32px]">
          <div
            className="rounded-[24px] w-1/2 btn !text-[32px] h-[96px] leading-[96px] cursor-pointer"
            onClick={() => setShowCancelReason(false)}
          >
            Back
          </div>
          <LoadingButton
            onClick={() => {
              if (!activeCancelReason) return
              onCancelTour()
            }}
            loading={cancelTourLoading}
            className={`${
              !activeCancelReason || cancelTourLoading ? '!bg-[#F4F5FA] !text-[#A6A9BB]' : '!bg-[#E74F4F] !text-white'
            } w-[calc(50%-16px)] !rounded-[24px] !py-[24px] text-center !ml-[16px]`}
          >
            <Typography className="!text-[32px] !font-Averta6 font-semibold  capitalize">Cancel Tour</Typography>
          </LoadingButton>
        </div>
      </Dialog>

      <Dialog
        open={isShowTourInfo}
        onClose={() => setShowTourInfo(false)}
        className={`${isMobile ? 'mobile-box' : ''} reason-list-dialog-box`}
      >
        {isMobile && (
          <div className="text-center mt-[16px] mb-[16px] w-[44px] mx-auto">
            <div className="w-[44px] h-[4px] rounded-full bg-[#C7CBD1]" />
          </div>
        )}

        <div className="mb-[48px] mt-[40px] flex justify-between">
          <p className="text-[#050A22] text-[32px] font-semibold">Canceled Tour</p>

          <div onClick={() => setShowTourInfo(false)}>
            <img src={DialogCloseIcon} className="w-[24px] h-[24px] relative top-[12px] cursor-pointer" alt="close icon" />
          </div>
        </div>

        <div className="max-h-[880px] mb-[24px] overflow-y-auto">
          <SelectedForm
            filledSelectedUnits={fillOnlySelectFloorplanSelectedUnits(selectedUnits, selectedProperty)}
            selectedUnits={selectedUnits}
            property={property}
            preference={preference}
            pageType="tourInfo"
            tourOption={getTourTypeName(currentTour.tourType)}
            tourTime={`${momentTimezone(currentTour.startTime).tz(currentTour.timeZone).format('h:mm a, dddd, MMM DD, YYYY')} ${
              currentTour.timeZoneText
            }`}
            tourHost={currentTour.tourAgentName}
            cancelReason={currentTour.cancelReason}
            cancelNote={currentTour.cancelNote}
          />
        </div>

        {isMobile && (
          <div
            className="my-[32px] rounded-[24px] btn !text-[32px] h-[96px] leading-[96px] cursor-pointer text-center font-semibold"
            onClick={() => setShowTourInfo(false)}
          >
            Close
          </div>
        )}
      </Dialog>
    </div>
  )
}

const PropertyCard: FC<{ property: ScheduleTourPropertyType; onClick: () => void }> = props => {
  const { property, onClick } = props
  return (
    <Box style={{ boxShadow: '0px 1px 7px 0px #242A2E1A' }} className="rounded-[24px] overflow-hidden" onClick={onClick}>
      <img src={property.headPicUrl} alt="" className="w-full h-[388px]" />
      <div className="p-[24px] flex items-start flex-col ">
        {property.minPrice && (
          <div className="mb-[16px] flex flex-row items-baseline justify-start">
            <Typography className="!font-Averta6 !font-bold !text-[#050A22] !text-[44px] ">{`$${getCurrency(
              property.minPrice
            )}+/mo`}</Typography>
            &nbsp;&nbsp;
            {property.minOriginalPrice && Number(property.minOriginalPrice) > Number(property.minPrice) && (
              <Typography className="!font-Averta4 line-through  !text-[#75798D] !text-[28px] ">
                {`$${getCurrency(property.minOriginalPrice)}`}
              </Typography>
            )}
          </div>
        )}
        <Typography className="!font-Averta6 !text-[#050A22] !text-[32px] !mb-[16px]">{property.aliasName || property.name}</Typography>
        {property.amenities.length > 0 && (
          <Box className="!font-Averta6 !text-[#050A22] !text-[32px] flex items-center flex-wrap h-[80px] overflow-hidden">
            {property.amenities.map(amenitie => (
              <span
                key={amenitie.catalogName}
                className="bg-[#F7F9FC] mr-[16px] mb-[16px] w-[fit-content] rounded-[16px] px-[16px] py-[8px] text-[#75798D] font-Averta4"
              >
                {amenitie.catalogName}
              </span>
            ))}
          </Box>
        )}
      </div>
    </Box>
  )
}

export default ScheduleTourDetail
