import { Box, Typography, useMediaQuery } from '@mui/material'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab, { tabClasses } from '@mui/joy/Tab'
import { Statsig } from 'statsig-react'
import ReserveCalendar from './reserveCalendar'
import { ScheduleTourPropertyType, SelectedUnitsType, selfGuidTourUnitsProps } from './contantsAndType'
import { checkIsMobile } from '../../utils/agent'
import { REPORT_NO_AVAILABLE_DAY } from '../../graphqls/mutations'
import { fillOnlySelectFloorplanSelectedUnits, calcTimeOverlap } from './utils'

const isMobile = checkIsMobile()

export default function ScheduleTourWayAndTime ({
  isCentralizedStage,
  selfGuidTourUnits,
  typeList,
  type,
  prospectId,
  selectedProperty,
  selectedUnits,
  agentInfo,
  timeZoneInfo,
  isReschedule,
  onChangeType,
  onChangeTime,
  onBackNewUserId,
  onNoAvailableDay,
}: {
  isCentralizedStage?: boolean
  selfGuidTourUnits?: selfGuidTourUnitsProps[]
  typeList: { key: string; name: string; desc: string }[]
  type: string
  prospectId: string
  selectedProperty: ScheduleTourPropertyType | undefined
  selectedUnits: SelectedUnitsType
  agentInfo: { id: string; name: string }
  timeZoneInfo: { displayText: string; timeZone: string }
  isReschedule?: boolean
  onChangeType: (_type: string) => void
  onChangeTime: (value: { date?: string; time: string; startSecond: string }) => void
  onBackNewUserId: (value: { newAdminUserId: string; newAdminUserName: string }) => void
  onNoAvailableDay?: () => void
}) {
  const { questionnaireId } = useParams()
  const tourTypeDesc: string = useMemo(() => typeList?.find(item => item?.key === type)?.desc || '', [typeList, type])

  const unitsTimeStrs = useMemo(() => {
    const result: string[] = []
    selectedUnits?.forEach((item: { unitsInfo: { unitName: string } }) => {
      result.push(item?.unitsInfo?.unitName)
    })
    return result
  }, [selectedUnits, selectedProperty])
  const showUnitsTimeStr = useMemo(() => {
    let result = false
    selectedUnits.forEach(item => {
      if (item.unitsInfo.rooms.length > 0) {
        if (item.unitsInfo.rooms.some(unit => unit.availableStatus !== 0)) {
          result = true
        }
      }
    })
    return result
  }, [selectedUnits, selectedProperty])

  const { isOverlap, min, max } = useMemo(
    () => calcTimeOverlap(fillOnlySelectFloorplanSelectedUnits(selectedUnits, selectedProperty)),
    [selectedUnits],
  )

  const [reportNoAvailableDay] = useMutation(REPORT_NO_AVAILABLE_DAY)

  return (
    <>
      <div className={`w-full  px-[40px] py-[32px] lg:pt-[96px] ${isReschedule ? 'hidden' : ''}`}>
        <Typography className='!font-Averta6 !text-[36px] !text-[#181414]'>Choose tour option</Typography>

        <Tabs
          aria-label='tabs'
          onChange={(_, v: string | number | null) => {
            Statsig.logEvent('schedule_tour_selected_tour_type', window.location.href, {
              prospectId,
              propertyId: String(selectedProperty?.id),
              propertyName: String(selectedProperty?.name),
              propertyType: 'Apartment',
              tourType: v?.toString() || '',
            })
            onChangeType(v?.toString() || '')
          }}
          defaultValue={typeList?.[0]?.key}
          size='sm'
          sx={{ bgcolor: 'transparent' }}
        >
          <TabList
            size='sm'
            disableUnderline
            sx={{
              width: isMobile ? '100%' : 'fit-content',
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              p: 0.2,
              gap: 0.5,
              borderRadius: 'lg',
              bgcolor: 'background.level1',
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: 'sm',
                bgcolor: 'background.surface',
              },
              height: '40px',
              fontSize: isMobile ? '16px' : '16px',
            }}
          >
            {typeList?.map(item => (
              <Tab
                sx={{
                  height: '37px',
                  minHeight: '37px',
                  mt: 0,
                  width: isMobile ? '100%' : 'fit-content',
                  boxSizing: 'border-box',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '13px',
                  fontWeight: 500,
                }}
                key={item?.key}
                value={item?.key}
                disableIndicator
              >
                {item?.name}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        {tourTypeDesc ? (
          <Typography className='!font-Averta4 !text-[28px] !mt-[16px] p-1 rounded-sm !text-[#858B98] bg-[rgba(247,249,252,1)]'>
            {tourTypeDesc}
          </Typography>
        ) : null}
      </div>
      <div className='w-full  px-[40px] py-[32px] lg:pt-[96px]'>
        <Typography className='!font-Averta6 !text-[36px] !mb-[32px] !text-[#181414]'>Please select tour date and time</Typography>
        {showUnitsTimeStr && (
          <Box className='!rounded-[16px] !pb-[16px]'>
            <Box className='!rounded-[16px] !bg-[#FFFAED] !p-[16px]'>
              <Typography className='!font-Averta4 !text-[28px] !text-[#000000] break-all'>
                {isOverlap ? (
                  <>
                    Optimal visit: {max === Infinity ? 'from ' : ''}
                    <span className='!font-Averta7'>
                      {min !== -Infinity ? moment(min).format('MM/DD/YYYY') : ''}
                      {max !== Infinity ? ` - ${moment(max).format('MM/DD/YYYY')}` : ''}
                    </span>
                  </>
                ) : (
                  <>No available time for you to tour all at once. Would you wanna replan it?</>
                )}
              </Typography>
              <ul className='list-disc !pl-[40px]'>
                {unitsTimeStrs?.map((str: string) => (
                  <li className='!font-Averta4 !text-[28px] !text-[#000000]' key={str}>
                    {str}
                  </li>
                ))}
              </ul>
              <Typography className='!font-Averta4 !text-[28px] !text-[#000000]'>Maximize your tour effortlessly!</Typography>
            </Box>
          </Box>
        )}
        <ReserveCalendar
          prospectId={prospectId}
          isCentralizedStage={isCentralizedStage}
          selectedUnits={selectedUnits}
          selectedProperty={selectedProperty}
          timeZone={timeZoneInfo.timeZone}
          timeZoneText={timeZoneInfo.displayText}
          adminUserId={agentInfo?.id}
          onBackUserId={onBackNewUserId}
          onNoAvailableDay={() => {
            reportNoAvailableDay({ variables: { input: { prospectId, questionnaireId } } })
            onNoAvailableDay?.()
          }}
          onChange={date => {
            onChangeTime(date)
            if (date.date) {
              Statsig.logEvent('schedule_tour_selected_tour_date', window.location.href, {
                prospectId,
                propertyId: String(selectedProperty?.id),
                propertyName: String(selectedProperty?.name),
                propertyType: 'Apartment',
                tourDate: date.date,
              })
            }
          }}
        />
      </div>
    </>
  )
}
