import React, { FC, useMemo } from 'react'
import { Divider, List, ListItem, Typography } from '@mui/material'
import { SelectedUnitsType, PageTypeType } from './contantsAndType'

interface FilledFormProps {
  property: { name: string; address: string }
  selectedUnits: SelectedUnitsType
  preference?: string[]
  pageType?: string
  tourOption?: string
  tourTime?: string
  tourHost?: string
  cancelReason?: string
  cancelNote?: string
}
const SelectedForm: FC<FilledFormProps> = props => {
  const { property, selectedUnits, preference, pageType, tourOption, tourTime, tourHost, cancelReason, cancelNote } = props
  const selectedUnitStrs = useMemo(() => {
    const result: string[] = []
    selectedUnits.forEach(item => result.push(item?.unitsInfo?.unitName))
    return result
  }, [selectedUnits])
  return (
    <List className={`${pageType === 'tourInfo' ? '' : '!py-[24px] !px-[46px]'}  w-full font-Averta`}>
      <div className='bg-[#F7F9FC] rounded-[16px]'>
        <ListItem className='!p-[32px] flex items-center !justify-between'>
          <span className='w-1/2 text-[28px] text-[#050A22] font-Averta4 self-start'>Property</span>
          <div className='w-1/2 text-left'>
            <Typography className='!text-[28px] !text-[#050A22] !font-Averta6'>{property.name}</Typography>
            <Typography className='!text-[28px] text-[#75798D] !font-Averta4'>{property.address}</Typography>
          </div>
        </ListItem>
        {selectedUnitStrs.length > 0 && (
          <>
            <Divider flexItem variant='middle' sx={{ borderColor: '#E0E2EF' }} component='li' />
            <ListItem className='!p-[32px] flex items-center !justify-between'>
              <span className='w-1/2 text-[28px] text-[#050A22] font-Averta4 self-start'>Unit</span>
              <div className='w-1/2 text-left'>
                <Typography className='!text-[28px] !text-[#050A22] !font-Averta6'>{selectedUnitStrs.join('; ')}</Typography>
              </div>
            </ListItem>
          </>
        )}
        {preference && preference.length > 0 && (
          <>
            <Divider flexItem variant='middle' sx={{ borderColor: '#E0E2EF' }} component='li' />
            <ListItem className='!p-[32px] flex items-center !justify-between'>
              <span className='w-1/2 text-[28px] text-[#050A22] font-Averta4 self-start'>Preference</span>
              <div className='w-1/2 text-left'>
                <Typography className='!text-[28px] !text-[#050A22] !font-Averta6'>{preference.join('; ')}</Typography>
              </div>
            </ListItem>
          </>
        )}
        {tourOption && (
          <>
            <Divider flexItem variant='middle' sx={{ borderColor: '#E0E2EF' }} component='li' />
            <ListItem className='!p-[32px] flex items-center !justify-between'>
              <span className='w-1/2 text-[28px] text-[#050A22] font-Averta4 self-start'>Tour Option</span>
              <div className='w-1/2 text-left'>
                <Typography className='!text-[28px] !text-[#050A22] !font-Averta6'>{tourOption}</Typography>
              </div>
            </ListItem>
          </>
        )}
        {tourTime && (
          <>
            <Divider flexItem variant='middle' sx={{ borderColor: '#E0E2EF' }} component='li' />
            <ListItem className='!p-[32px] flex items-center !justify-between'>
              <span className='w-1/2 text-[28px] text-[#050A22] font-Averta4 self-start'>Tour Time</span>
              <div className='w-1/2 text-left'>
                <Typography className='!text-[28px] !text-[#050A22] !font-Averta6'>{tourTime}</Typography>
              </div>
            </ListItem>
          </>
        )}
        {tourHost && (
          <>
            <Divider flexItem variant='middle' sx={{ borderColor: '#E0E2EF' }} component='li' />
            <ListItem className='!p-[32px] flex items-center !justify-between'>
              <span className='w-1/2 text-[28px] text-[#050A22] font-Averta4 self-start'>Tour Host</span>
              <div className='w-1/2 text-left'>
                <Typography className='!text-[28px] !text-[#050A22] !font-Averta6'>{tourHost}</Typography>
              </div>
            </ListItem>
          </>
        )}

        {cancelReason && (
          <>
            <Divider flexItem variant='middle' sx={{ borderColor: '#E0E2EF' }} component='li' />
            <ListItem className='!p-[32px] flex items-center !justify-between'>
              <span className='w-1/2 text-[28px] text-[#050A22] font-Averta4 self-start'>Cancellation reason</span>
              <div className='w-1/2 text-left'>
                <Typography className='!text-[28px] font-semibold !text-[#050A22] !font-Averta6'>{cancelReason}</Typography>
                <Typography className='!text-[28px] font-normal !text-[#75798D] !font-Averta6'>{cancelNote || ''}</Typography>
              </div>
            </ListItem>
          </>
        )}
      </div>
    </List>
  )
}
export default SelectedForm
