export const PreferenceList = [
  {
    value: 'UPPER_FLOOR',
    label: 'Upper Floor',
  },
  {
    value: 'DAYLIGHT',
    label: 'Daylight',
  },
  {
    value: 'FACE_COURTYARD',
    label: 'Face Courtyard',
  },
  {
    value: 'FACE_STREET',
    label: 'Face Street',
  },
  {
    value: 'MORE_STORAGE',
    label: 'More Storage',
  },
]

export const TourCancelReasonList: string[] = [
  'Time conflict',
  'No longer interested in this property',
  'No longer available ',
  'Other reason',
]

export const TourStatusList = {
  SCHEDULED: 'scheduled',
  CANCELED: 'canceled. Feel free to reschedule anytime.',
  FINISHED: 'completed',
}

export const TourTypeList = [
  {
    key: 'IN_PERSON_TOUR',
    name: 'In-Person Tour',
  },
  {
    key: 'VIRTUAL_TOUR',
    name: 'Virtual Tour',
  },
  {
    key: 'IN_PERSON_TOUR (Group)',
    name: 'In-Person Tour (Group)',
  },
  {
    key: 'VIRTUAL_TOUR (Group)',
    name: 'Virtual Tour (Group)',
  },
]

export const getTourTypeName = (key: string) => TourTypeList.find(item => item.key === key)?.name || '-'

export interface RentPriceType {
  baseAmount: number
  discountAmount: number
  leaseTerm: number
  moveInDate: number
}
export interface RoomType {
  availableNow: boolean
  availableStatus: number
  availableStartTime: number
  tourAvailableStatus?: number
  tourAvailableStartTime?: number
  preTourEndDate: number
  preTourStartDate: number
  id: string
  name: string
  privateBathroom: boolean
  propertyId: string
  rentPriceLeaseTerm: string
  unitId: string
  rentPrice: RentPriceType
}

export interface AvailableUnit {
  id: string
  name: string
  minSqrFt: string
  maxSqrFt: string
  floorPlanPicUrl: string
  rentPrices: RentPriceType[]
  maxRentPrice: RentPriceType
  minRentPrice: RentPriceType
  intention: boolean
  availableStatus: number
  preTourEndDate: number
  preTourStartDate: number
  availableStartTime: number
  floorPlanBathroomNum: number
  floorPlanBedroomNum: number
  floorPlanIndex: number
  rooms: RoomType[]
}

export interface BedRoomOptionType {
  key: string
  label: string
  value: string
}
export interface FloorPlanModeUnitType {
  floorPlanIndex: number
  availableUnits: AvailableUnit[]
  bedroomOption: BedRoomOptionType
}

export interface ScheduleTourPropertyType {
  id: string
  aliasName: string
  name: string
  address: string
  headPicUrl: string
  availableStartTime: number
  minPrice: string
  minOriginalPrice: string
  coLivingStatus: 'CO-LIVING' | null
  flexibleLeaseTermTip: string | null
  floorPlanModeUnit: FloorPlanModeUnitType[]
  amenities: { catalogName: string }[]
}

export interface ScheduleTourTourType {
  id: string
  propertyName: string
  propertyId: string
  status: 'SCHEDULED' | 'FINISHED' | 'CANCELED'
  tourAgentId: string
  tourAgentName: string
  startTime: string
  timeZoneText: string
  timeZone: string
  propertyAddress: string
  preference: string[]
  units: { floorPlanName: string; unitId: number; unitName: string }[]
  tourType: 'IN_PERSON_TOUR' | 'VIRTUAL_TOUR'
  eventId: string
  cancelReason: string
  cancelNote: string
}

export interface PreSelectedUnitInfo {
  propertyId: number
  propertyName: string | null
  roomIds: number[]
  unitId: number
}

export interface SelectedUnitInfoProps {
  floorPlanIndex: number
  unitName: string
  unitTitle: string
  unitId: number
  rooms: {
    unitId: number
    name: string
    id: number
    availableStatus: number
    preTourEndDate: number
    preTourStartDate: number
    availableStartTime: number
  }[]
}

export interface selfGuidTourUnitsProps {
  floorPlanIndex?: number
  floorPlanName: string
  modelUnitId: number
  modelUnitName: string
  units: { unitId: number; unitName: string }[]
}
export interface OtherUnitInfoProps {
  floorPlanIndex: number
  floorPlanName: string
  floorPlanTitle: string
  name: string
}

export type PageTypeType = 'unit' | 'preference' | 'submitTour' | 'tourInfo'
export type SelectedUnitsType = Map<string, { unitsInfo: SelectedUnitInfoProps; otherUnit?: OtherUnitInfoProps }>
export default {}
